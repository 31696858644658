
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IFeeGrid } from '../types';

@Component
export default class ClinicFee extends Vue {
  @Prop({ default: () => { } })
  readonly grid: IFeeGrid | undefined;

  @Prop({ default: () => { } })
  readonly storing: boolean | undefined;

  async storePlace() {
    this.$emit('store', this.grid)
  }

  async removePlace() {
    this.$emit('remove', this.grid)
  }
}
